document.addEventListener('DOMContentLoaded', function() {
    const leaderboard = document.querySelector('.leaderboard');

    if (!leaderboard) { return; }
    
    const rows = document.querySelectorAll('.leaderboard table tbody tr');
    let draggingRow = null;

    // Check if rows are present
    if (rows.length === 0) {
        console.log('No rows found.');
    } else {
        console.log(`Found ${rows.length} rows that are draggable now.`);
    }

    rows.forEach(row => {
        // Make the row draggable
        row.setAttribute('draggable', 'true');

        // Drag start event - store the dragged row
        row.addEventListener('dragstart', (e) => {
            // console.log(`Dragging started for row: ${row.textContent.trim()}`);
            draggingRow = row;
            row.style.backgroundColor = 'yellow';
            e.dataTransfer.effectAllowed = 'move';  // Allow move effect
        });

        // Drag end event - reset the background color
        row.addEventListener('dragend', () => {
            row.style.backgroundColor = '';
            draggingRow = null;
        });

        // Drag over event - prevent the default action
        row.addEventListener('dragover', (e) => {
            e.preventDefault();  // Necessary to allow a drop
            e.dataTransfer.dropEffect = 'move';  // Show move effect
        });

        // Drag enter event - highlight the row being dragged over
        row.addEventListener('dragenter', (e) => {
            e.preventDefault();
            if (row !== draggingRow) {
                row.style.backgroundColor = '#f0f0f0';  // Visual indication
            }
        });

        // Drag leave event - reset the background color
        row.addEventListener('dragleave', (e) => {
            row.style.backgroundColor = '';  // Reset background color
        });

        // Drop event - move the dragged row to the new position
        row.addEventListener('drop', (e) => {
            e.preventDefault();
            if (draggingRow && draggingRow !== row) {
                const tbody = row.closest('tbody');

                // Insert the dragged row before or after the row it was dropped on
                if (e.clientY < row.getBoundingClientRect().top + row.offsetHeight / 2) {
                    tbody.insertBefore(draggingRow, row);  // Insert above
                } else {
                    tbody.insertBefore(draggingRow, row.nextSibling);  // Insert below
                }
                // console.log(`Row reordered.`);
            }
        });
    });
});
